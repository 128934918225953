import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { addInsuranceForPatient, getConfigurationForPayer } from '../../../../core/service/services';
import Asterisk from '../../../commons/Asterisk';
import { LoadingButton } from '../../../commons/LoadingButton';
import DateConfType from './DateConfType';
import DropDownConfType from './DropDownConfType';
import TextConfType from './TextConfType';
import { toastError } from '../../../../App';



interface IProps {
    isOpen: boolean;
    toggle: any;
    onSave: any;
    payerCode: string | undefined | null
    payerName: string | undefined | null
    onCancel: any;
    dependentId?: number
}
export interface IDropDownValues {
    code: string;
    value: string;
}

export interface IConfElement {
    type: string;
    label: string;
    order: number;
    allowed: boolean;
    required: boolean;
    errorMessage: string;
    defaultValue: string;
    defaultSystemValue: string;
    values: IDropDownValues[] | null;
    keyToBeSent: string,
    min: string | null,
    max: string | null


}
enum configTypes {
    Unsupported = "Unsupported",
    Date = "Date",
    Collection = "Collection",
    Text = "Text",
    Enumeration = "Enumeration"
}


const ConfigurationInsuranceModal = (props: IProps) => {
    const [confList, setConfList] = useState<IConfElement[]>([])
    const [cardHolder, setCardHolder] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [errorMessageMap, setErrorMessageMap] = useState<Map<string, string>>(new Map());
    // error message map is used to keep track of the dynamically injected views so that if an issue happened we change the error message and it appears beneath the injected view 

    useEffect(() => {
        //get configurations
        if (props.isOpen) {
            if (props.payerCode) {
                getConfigurationForPayer(props.payerCode).then((result) => {
                    var newArr: IConfElement[] = []
                    const keyToBeSent: string[] = [];
                    const messageMap = new Map;

                    const keys = Object.entries(result.configurations)
                    keys.map(([key, value], idx) => {

                        var tempOneToChangeUnknown: any = value;
                        var tempOfConfType: IConfElement = { ...tempOneToChangeUnknown, keyToBeSent: key }
                        newArr = [...newArr, tempOfConfType]
                        if (tempOfConfType.required && tempOfConfType.type !== configTypes.Collection) {
                            keyToBeSent.push(key)
                            messageMap.set(key, "")
                            console.log("keys to be sent", key)
                        }

                    });

                    setConfList(newArr)
                    setErrorMessageMap(messageMap)
                }).catch((err) => {
                    console.log(err)
                });
            } else {
                console.log("no payer code")
            }
        }
    }, [props]);



    const renderDynamicConf = () => {
        return (
            <>
                {confList.map((configElement, idx) => {
                    if (configElement.required) {
                        switch (configElement.type) {
                            case configTypes.Text: {
                                return (
                                    <TextConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                                break
                            }
                            case configTypes.Date: {
                                return (
                                    <DateConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                                break
                            }
                            case configTypes.Enumeration: {
                                return (
                                    <DropDownConfType confElement={configElement} errorMessage={errorMessageMap.get(configElement.keyToBeSent)} />
                                )
                                break
                            }
                        }
                    }

                })

                }

            </>
        )
    }
    const clearErrorMap = () => {
        const tempMap = new Map()
        errorMessageMap.forEach((value, key) => {
            tempMap.set(key, "")
        })
        setErrorMessageMap(tempMap)
    }
    
    const onSubmitForm = (e: any) => {
       setLoading(true);
       clearErrorMap();
       const submittedObj: any = {};
       e.preventDefault();

       let keys = Array.from(errorMessageMap.keys());
       keys.forEach((key) => {
          const temp: string = e.target[key].value;
          submittedObj[key] = temp;
       });

       submittedObj['payerCode'] = props.payerCode;
       submittedObj['payerName'] = props.payerName;

       addInsuranceForPatient(
          (response) => !response.coverage.pending,
          submittedObj,
          props.dependentId
       )
          .then((res) => {
             setLoading(false);
             props.onSave(res.insurance);
          })
          .catch((err) => {
             if (err && err.json) {
                toastError(err.json.message);

                let errors = err.json.errors as Array<{
                   field?: string;
                   errorMessage: string;
                }>;

                errors.forEach((jsonObject) => {
                   if (jsonObject.field)
                      setErrorMessageMap(
                         new Map(
                            errorMessageMap.set(
                               jsonObject.field,
                               jsonObject.errorMessage
                            )
                         )
                      );
                   else toastError(jsonObject.errorMessage);
                });
             } else toastError(err);
             setLoading(false);
          });
    };

    const renderNotMainCardHolder = () => {
        return (
            <>
                <Label for="firstName">Card Holder's First Name <Asterisk /></Label>
                <Input
                    type="text"
                    id="firstName"
                    name="firstName"
                    required
                ></Input>
                <br />
                <Label for="lastName"> Card Holder's Last Name <Asterisk /></Label>
                <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    required
                ></Input>
                <br />

                <Label for="dateOfBirth">Card Holder's Date Of Birth <Asterisk /></Label>
                <Input id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    required
                    className="dateOfBirth"

                />
                <br />
                <Label for="relation">Card Holder's Relation <Asterisk /></Label>

                {/* todo get this dynamic           */}
                <Input
                    type="select"
                    defaultValue={"Spouse"}
                    required
                    id="relation"
                    name="relation"

                >
                    <option key={"Spouse"} value={"Spouse"} >
                        Spouse
                    </option>
                    <option key={"FamilyMember"} value={"FamilyMember"} >
                        FamilyMember
                    </option>
                    <option key={"NonFamilyMember"} value={"NonFamilyMember"} >
                        NonFamilyMember
                    </option>
                </Input>
            </>
        )
    }
    return (
        <div>
            <Modal isOpen={props.isOpen}>
                <ModalHeader>Please fill the following info</ModalHeader>
                <Form onSubmit={onSubmitForm} >
                    <ModalBody>
                        <FormGroup>
                            {renderDynamicConf()}
                            {/* <div className="custom-checkbox custom-control">
                                <Input

                                    id="cardCarrier"
                                    type="checkbox"
                                    defaultChecked={true}
                                    // disabled={!enabled}
                                    disableUnderline={true}
                                    onChange={(e) => {
                                        setCardHolder(
                                            e.target.checked,
                                        );
                                    }}
                                // className="custom-control-input"
                                />
                                <Label>Primary Subscriber</Label>
                                <br />

                            </div>
                            {!cardHolder && renderNotMainCardHolder()} */}


                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <FormGroup>
                            <LoadingButton
                                type="submit"
                                // onClick={saveAndContinueModal}
                                color="primary"
                                isLoading={isLoading}
                            >
                                Save

                            </LoadingButton>
                            {"  "}
                            <Button color="secondary" onClick={props.onCancel}>
                                Cancel
                            </Button>
                        </FormGroup>
                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    );
};

export default ConfigurationInsuranceModal;



