import { useGenericServiceCall } from "./GenericServiceCall";
import { appConfig } from "../../config/appConfig";
import { UserConfig, IUserInfo } from "../../config/userConfig";
import { genericPollingRequest, genericRequest, ServiceDescriptor } from "./ServiceCall";
import HttpStatus from "http-status-codes";
import { IQuickMedicalHistory } from "../../page/medical-history/MedicalHistortTypes";
import { ITemplate } from "../../page/settings/PhysicianTemplates";
import { IAppointments } from "../../page/dashboard/admin/AppointmentsOverview";
import {
  IUSState,
  IPHInfo,
  IEligibleState,
} from "../../page/settings/PhysicianSettings";
import {
  IPatientInfoSettings,
  IDPatient,
  ISearchPharmacyParams,
  IPharmacySearch,
} from "../../components/patientSettings/PatientSettings";
import {
  IBooking,
  IAppointmentInfo,
  IFullAppointment,
  AppointmentStatus,
  IRxChangeRequest,
  IMedicationSearchItem,
} from "../../page/book-appointment/AppointmentModels";
import { IMessage } from "../../page/chat-module/MessageModel";
import { IResponseMessageChat } from "../../page/chat-module/ResponseMessage";
import { IPhysician } from "../../page/dashboard/admin/PhysiciansOverview";
import { IAddAllergy, IFullAllergy, ISearchResultDto } from "../../components/medicalHistory/MedicationAllergiesDoseSpot";
import { AllergyInteractionResponse, IItemAllergyInteraction } from "../../page/dashboard/physician/PhysicianAppointmentDetails";
import { IDoseSotPrescription, IDrugInteractionItem } from "../../page/dashboard/physician/AppointmentPrescriptions";
import { ISelfReportedMedication } from "../../page/medical-history/SelfReportedMedicationsModal";
import { IRxChangeDenialReason } from "../../page/dashboard/physician/RxChangeModal";
import { IAgentRegistrationInfo } from "../../page/auth/agent/AgentRegistration";
import { IAgent } from "../../page/dashboard/admin/AgentsOverview";
import { IAgentAuditLogPreview } from "../../page/dashboard/admin/AgentHistory";
import { IListPatientsResponse, IListPatientsRequestQuery, IPatientForAgents } from "../../page/dashboard/agent/PatientsOverview";
import { IPayerPreview } from "../../page/avility/InsuranceAdmin";
import { IBiller } from "../../page/dashboard/admin/biller-overivew/BillerOverview";
import { ICopayment, IInsuranceResponse } from "../../redux/types/interfaces/bookingInterfaces";
import { IAppointmentBiller, IBillerSearch, IInitialFees } from "../../page/dashboard/biller/HomeBiller";
import { DataForAmount, IAppointementBilling, IAppointementBillingUpdate } from "../../page/dashboard/biller/ManageInsuranceModal";
import { IBillingLogs } from "../../page/dashboard/biller/BillingLogsModal";
import { IDiagnosis } from "../../page/dashboard/physician/PhysicianDiagnosis";
import { IDoseSpotPrescription } from "../../redux/types/interfaces/medicalProfileInterfaces";
import { InsuranceCoverageCheckResponse } from "../types/Insurance";

export interface IPhysicianRegistrationForm {
  email: string;
  password: string;
  passwordConfirmation: string;
  contactInfo: {
    title?: string | null;
    firstname: string;
    lastname: string;
    middlename?: string;
    officePhone?: string;
    mobilePhone: string;
    dob: string | Date;
    primaryFax: string;
    addressLine1: string,
    addressLine2?: string,
    city: string,
    stateId: number,
    zipCode: string,
  },
  physicianInfo: {
    dea: string;
    specialty: string;
    npi: string;
  }
}
export interface IPaginatedResponse<T> {
  items: T[];
  pageNum: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export interface IAuthInput {
  fullname: string;
  email: string;
  password: string;
  authenticationId?: string;
  authenticationCode?: string;
  repeatPassword: string;
  agentAsPatient?: boolean;
  dob?: string | Date;
  phone: string
}


export interface IRxReconcile {
  id: string,
  doseSpotId: string,
  originalDoseSpotPrescriptionId: string,
  rxChangeStatus: string,
  doseSpotPatientId: string,
  doseSpotClinicianId: string,
  patientFirstName: string,
  patientLastName: string,
  doseSpotDisplayName?: string

}

export const usePatientSignUpAPI = () => {
  const signUpPath = "/api/patient/register";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + signUpPath;

  return useGenericServiceCall<undefined, IAuthInput, SignUpErrorResponse>(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};

interface SignUpErrorResponse {
  status: number;
  details: string;
  message: string
}

export const usePhysicianRegistrationAPI = () => {
  const signUpPath = "/api/physician/register";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + signUpPath;

  return useGenericServiceCall<undefined, IPhysicianRegistrationForm, { message: string }>(url, "POST");
};

export const useAgentRegistrationAPI = () => {
  const url = `${appConfig.baseUrl}/api/agent/register`;

  return useGenericServiceCall<undefined, IAgentRegistrationInfo, { message: string }>(url, "POST");
}
export const useBillerRegistrationAPI = () => {
  const url = `${appConfig.baseUrl}/api/biller/register`;

  return useGenericServiceCall<undefined, IAgentRegistrationInfo, { message: string }>(url, "POST");
}

export enum LoadingState {
  "loading",
  "succeed",
  "failed",
  "nuteral",
}

export const allowedLoginErrorCodes = [
  HttpStatus.INTERNAL_SERVER_ERROR,
  HttpStatus.UNAUTHORIZED,
  HttpStatus.LOCKED,
  HttpStatus.NOT_FOUND,
  HttpStatus.CONFLICT,
  HttpStatus.BAD_REQUEST
] as const;
// type LoginErrorCodes = typeof allowedLoginErrorCodes[number];  // "apple" | "peach"

interface LoginErrorResponse {
  reason: string;
  details: string;
  responseCode: number;
  message: string
}

export const useSignInAPI = () => {
  const path = "/api/patient/login";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + path;

  return useGenericServiceCall<
    { accessToken: string; userInfo?: IUserInfo },
    Pick<IAuthInput, "email" | "password">,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};

export const useAgentAuthService = () => {
  const url = `${appConfig.baseUrl}/api/agent/login`;
  return useGenericServiceCall<
    { accessToken: string; userInfo?: IUserInfo },
    Pick<IAuthInput, "email" | "password">,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};


export const useBillerAuthService = () => {
  const url = `${appConfig.baseUrl}/api/biller/login`;
  return useGenericServiceCall<
    { accessToken: string; userInfo?: IUserInfo },
    Pick<IAuthInput, "email" | "password">,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};


export const useAdminAuthService = () => {
  const path = "/api/admin/login";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + path;
  return useGenericServiceCall<
    { accessToken: string; userInfo?: IUserInfo },
    Pick<IAuthInput, "email" | "password">,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};

export const usePhysicianAuthService = () => {
  const path = "/api/physician/codes/authcode";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + path;

  return useGenericServiceCall<
    { authenticationId: string },
    Pick<IAuthInput, "email" | "password">,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};

export const usePhysicianLoginService = () => {
  const path = "/api/physician/login";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + path;

  return useGenericServiceCall<
    { accessToken: string; userInfo?: IUserInfo },
    Pick<
      IAuthInput,
      "email" | "password" | "authenticationId" | "authenticationCode"
    >,
    LoginErrorResponse
  >(url, "POST", undefined, undefined, [...allowedLoginErrorCodes]);
};

export const usePasswordResetRequestService = (userType: string) => {
  const path = "/api/" + userType + "/codes/resetrequest";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + path;

  return useGenericServiceCall<
    { message: string },
    Pick<IAuthInput, "email">
  >(url, "POST");
};

interface IPasswordResetFulfillRequest {
  email: string;
  code: string;
  codeId: string;
  password: string;
}
export const usePasswordResetFulfilltService = (userType: string) => {
  const signUpPath = "/api/" + userType + "/codes/resetpassword";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + signUpPath;

  return useGenericServiceCall<
    { message: string },
    IPasswordResetFulfillRequest
  >(url, "POST");
};

interface IVerifyAccountRequest {
  email: string;
  code: string;
}
export const useActivateAccountService = (userType: string) => {
  const verifyPath = "/api/" + userType + "/codes/verify";
  const baseUrl = appConfig.baseUrl;
  const url = baseUrl + verifyPath;

  return useGenericServiceCall<
    {
      message: string;
    },
    IVerifyAccountRequest
  >(url, "POST");
};

const defaultProvide = () => UserConfig.getToken();
const defaultUpdater = (token: string) => UserConfig.setToken(token);

export const getAllConditionsLookup: () => Promise<
  { id: number; name: string }[]
> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/lookup/symptoms",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getSelectedConditions = (
  patientId?: number
): Promise<{
  symptoms: number[];
  surgeries: number[];
}> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/surgeriessymptoms" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const setSelectedConditions = (data: number[]) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/symptoms",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export interface IPhysicianTemplate {
  id: string;
  text: string;
  type: "followup" | "symptoms";
  label: string;
}

export const getPhysitianTemplates: () => Promise<
  IPhysicianTemplate[]
> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/template/all",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export enum RelationshipTo {
  Spouse = "Spouse",
  FamilyMember = "FamilyMember",
  NonFamilyMember = "NonFamilyMember",
}
export interface ISelectConditions {
  symptoms?: number[];
  surgeries?: number[];
}
export interface IPatientInfo {
  contact?: {
    firstname?: string;
    lastname?: string;
    phone?: string;
    email?: string;
    dateOfBirth?: Date | string;
  };
  address?: {
    city?: string;
    description?: string;
    stateId?: number;
    zipCode?: string;
    country?: string;
    stateName?: string
  };
  pharmacy?: {
    address?: string;
    name?: string;
    phone?: string;
  };
  dependents?: {
    current?: [
      {
        id?: number;
        gender?: "m" | "f";
        firstname?: string;
        lastname?: string;
        pregnant?: boolean;
        breastfeeding?: boolean;
        //relationshipToDependable: RelationshipTo;
        relationshipToDependable?: string;
        // age?: number;
      }
    ];
  };
}

export const getPatientInfo = (patientId?: number): Promise<IPatientInfo> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/info" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const setPatientInfo = (data: IPatientInfo) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/info",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const setPhysicianTemplatesService = (data: ITemplate[]) => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/template/all",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const getUSStatesLookup: () => Promise<IUSState[]> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/lookup/usstates",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getAllUSStateLookUpForPatient: () => Promise<IUSState[]> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/lookup/allUsStates",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const uploadImage = (
  data: FormData
): Promise<{ photoToken: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/uploads/image",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const savePhysicianInfo = (data: IPHInfo, phId?: string | null) => {
  let descriptor = new ServiceDescriptor(
    !phId || phId === null
      ? "/api/physician/info"
      : "/api/admin/physician/" + phId + "/info",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const getPhysicianInfo = (phId?: string | null): Promise<IPHInfo> => {
  let descriptor = new ServiceDescriptor(
    !phId || phId === null
      ? "/api/physician/info"
      : "/api/admin/physician/" + phId,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getImage = (
  photoToken: string,
  phId?: string | null
): Promise<{ imageBase64: string,name:string }> => {
  let descriptor = new ServiceDescriptor(
    !phId || phId === null
      ? "/api/uploads/image/base64/" + photoToken
      : "/api/admin/physician/" + phId + "/image/base64",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const savePhysicianEligibleStates = (data: IEligibleState[]) => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/state",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const getPhysicianEligibleStates = (
  phId?: string | null
): Promise<IEligibleState[]> => {
  let descriptor = new ServiceDescriptor(
    !phId || phId === null
      ? "/api/physician/state"
      : "/api/admin/physician/" + phId + "/state",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export interface agentHistoryResponse {
  logs: IAgentAuditLogPreview[],
  count: number,
  pageSize: number,
  pageIndex: number
}
export const getAgentHistory = (id: number, pageIndex: number, pageSize: number): Promise<agentHistoryResponse> => {
  let descriptor = new ServiceDescriptor(
    `/api/admin/agent/${id}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const getAppointmentHistory = (id: string): Promise<IAppointmentHistoryItem[]> => {
  let descriptor = new ServiceDescriptor(
    `/api/admin/appointment/${id}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const getAppointments = (
  pageIndex: number,
  pageSize: number,
  params?: any
): Promise<IAppointments> => {
  let url: string = `/api/admin/appointments?pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (params) {
    url = url.concat(
      (params.appointmentNumber
        ? `&appointmentNumber=${params.appointmentNumber}`
        : "") +
      (params.patientName ? `&patientName=${params.patientName}` : "") +
      (params.physicianName ? `&physicianName=${params.physicianName}` : "") +
      (params.status ? `&status=${params.status}` : "") +
      (params.from ? `&from=${params.from}` : "") +
      (params.to ? `&to=${params.to}` : "") +
      (params.stateId ? `&stateId=${params.stateId}` : "")
    );
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IPatientMeasurment {
  gender?: "f" | "m";
  heightFt?: number;
  heightIn?: number;
  weight?: number;
  pregnant?: boolean | false;
  breastfeeding?: boolean | false;
}

export const getPatientMeasurments = (
  patientId?: number
): Promise<IPatientMeasurment> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/measurments" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const setPatientMeasurments = (data: IPatientMeasurment, patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/measurments" + (patientId ? "/" + patientId : ""),
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export interface IMedicationItem {
  id: number;
  name: string;
}
export interface IModifyMedicationsRequest {
  add: string[];
  modify: IMedicationItem[];
  remove: number[];
}

export const getPatientMedications = (
  patientId?: number
): Promise<IFullAllergy[]> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/medications" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const setPatientMedications = (data: IModifyMedicationsRequest) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/medications",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const setPatientMedicalHistory = (data: IQuickMedicalHistory) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/quick-medical-history",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};
export const requestPatientInfo = (
  methodType: "GET" | "POST" | "PUT",
  data?: {}
): Promise<IPatientInfoSettings> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/info",
    methodType,
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const updateAppointmentPharmacy = (id: string, data: IPharmacySearch) => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/${id}/pharmacy`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};
export const getPatientDependent = (id?: number): Promise<IDPatient> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dependent/:${id}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const postPatientDependent = (
  data?: {},
  id?: number
): Promise<IDPatient> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dependent`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};
export const putPatientDependent = (
  data?: {},
  id?: number
): Promise<IDPatient> => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/dependent/${id}` : `/api/patient/dependent`,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};
export const getPatientDependentMeasurements = (
  id?: number
): Promise<IPatientMeasurment> => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/measurments/${id}` : `/api/patient/measurments`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export interface IBillingAddress {
  firstname?: string;
  lastname?: string;
  email?: string;
  description?: string;
  city?: string;
  stateId?: number;
  country?: string;
  zipCode?: string;
}

export interface ICreditCardListingItem {
  id: string;
  cardLabel: string;
  expiration: string;
  cardNumber: string;
  address: IBillingAddress;
}

export const getPatientCreditCards: () => Promise<
  ICreditCardListingItem[]
> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/creditcards",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IConsultationReasons {
  id: number;
  name: string;
  type: string;
}
export const getConsultationReasons: () => Promise<
  IConsultationReasons[]
> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/consultationtypes",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const uploadMultipleImagesForPatient = (
  data: FormData
): Promise<[{ photoToken: string; photoName: string }]> => {
  let descriptor = new ServiceDescriptor(
    "/api/uploads/images",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};
export const getPatientDependentMedications: (
  id?: number
) => Promise<IMedicationItem[]> = (id) => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/medications/${id}` : `/api/patient/medications`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const getPatientDependentSelectedConditions: (
  id?: number
) => Promise<{
  symptoms: number[];
  surgeries: number[];
}> = (id) => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/surgeriessymptoms/${id}` : `/api/patient/surgeriessymptoms`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const postPatientDependentMedications: (
  id?: number,
  data?: IModifyMedicationsRequest
) => Promise<void> = (id, data) => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/medications/${id}` : `/api/patient/medications`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

// export const postPatientDependentSelectedConditions: (
//   id?: number,
//   data?: ISelectConditions
// ) => Promise<ISelectConditions> = (id, data) => {
//   let descriptor = new ServiceDescriptor(
//     `/api/patient/surgeriessymptoms/${id}`,
//     'POST',
//     defaultProvide,
//     defaultUpdater
//   );
//   return genericRequest(descriptor, data);
// };

export const postPatientDependentSelectedSymptoms: (
  data: number[],
  id?: number,
) => Promise<void> = (data, id) => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/symptoms/${id}` : `/api/patient/symptoms`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const postPatientDependentMeasurements: (
  id?: number,
  data?: IPatientMeasurment
) => Promise<IPatientMeasurment> = (id, data) => {
  let descriptor = new ServiceDescriptor(
    id ? `/api/patient/measurments/${id}` : `/api/patient/measurments`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export interface IPatientPaymentMethod {
  nameOnCard?: string;
  cardNumber?: string;
  expiration?: string;
  cvv?: string;
  address?: {
    firstname?: string;
    lastname?: string;
    email?: string;
    description?: string;
    city?: string;
    stateId?: number;
    zipCode?: string;
    country?: "US";
  };
}

export const addNewPaymentMethod = (data: IPatientPaymentMethod) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/creditcards",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const deleteCreditCard = (cardId: string) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/creditcards/" + cardId,
    "DELETE",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export type MissingStatus = "NotSet" | "Complete";

export interface IMissingSections {
  pharmacy: MissingStatus;
  medications: MissingStatus;
  alergies: MissingStatus;
  previousConditions: MissingStatus;
  measurment: MissingStatus;
  gender: MissingStatus;
  contactInfo: MissingStatus;
  address: MissingStatus;
}

export const getMissingSectionsForBooking = (
  patientId?: number
): Promise<IMissingSections> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/missinginfo" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const createAppointment = (
  appointment: IBooking
): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, appointment);
};

export const getWaitingAppointments = (): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IAppointmentInfo[];
  locked: IAppointmentInfo[];
}> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/waiting?pageIndex=0&pageSize=50",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};



export const getApointemetsForBiller = (

  pageSize: number,
  pageIndex: number,
  search: IBillerSearch,
  signal: AbortSignal
): Promise<{
  pageIndex: number;
  pageSize: number;
  count: number;
  appointments: IAppointmentBiller[];
  initialFees: IInitialFees
}> => {
  let url = `/api/biller/appointments?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (search) {
    console.log(search)

    url = url.concat(
      (search.patientName ? `&patientName=${search.patientName}` : "") +
      (search.payerCode ? `&payerCode=${search.payerCode}` : "") +
      (search.payerName ? `&payerName=${search.payerName}` : "") +
      (search.patientEmail ? `&patientEmail=${search.patientEmail}` : "") +
      (search.appointmentDateFrom ? `&appointmentDateFrom=${search.appointmentDateFrom}` : "") +
      (search.appointmentDateTo ? `&appointmentDateTo=${search.appointmentDateTo}` : "") +
      (search.insuranceListed !== undefined ? `&insuranceListed=${search.insuranceListed}` : "") +
      (search.appointmentNumber ? `&appointmentNumber=${search.appointmentNumber}` : "") +
      (search.patientPhone ? `&patientPhone=${search.patientPhone}` : "") +
      (search.patientID ? `&patientID=${search.patientID}` : "") +
      (search.billingStatus ? `&billingStatus=${search.billingStatus}` : "")
    )
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined, signal);
};

interface IAppointmentResponse {
  pageIndex: number;
  pageSize: number;
  items: IAppointmentInfo[];
  length: number;
}


export const getPhysicianPastAppointments = (
  search: ISearch,
  pageSize: number,
  pageIndex: number,
  signal?:AbortSignal
): Promise<IAppointmentResponse> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/past?pageIndex=${pageIndex}&pageSize=${pageSize}` +
    (search.patientName ? `&patientName=${search.patientName}` : "") +
    (search.patientCode ? `&patientCode=${search.patientCode}` : "") +
    (search.status ? `&status=${search.status}` : "") +
    (search.from ? `&from=${search.from}` : "") +
    (search.to ? `&to=${search.to}` : "") +
    (search.appointmentNumber
      ? `&appointmentNumber=${search.appointmentNumber}`
      : "") +
    (search.state ? `&state=${search.state}` : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined, signal);
};

export const getUpcomingAppointments = (): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IAppointmentInfo[];
}> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/upcoming-appointments?pageIndex=0&pageSize=50",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPatientPastAppointments = (
  search: ISearch,
  pageSize: number,
  pageIndex: number
): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IAppointmentInfo[];
  length: number;
}> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/past-appointments?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPatientAppointmentDetails = (
  id: string
): Promise<IFullAppointment> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const reschedulePatientAppointment = (
  id: string,
  newDate: Date
): Promise<{ message: string; app: IAppointmentInfo }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/reschedule/" + id,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { rescheduledDate: newDate });
};

export const cancelPatientAppointment = (
  id: string
): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/cancel/" + id,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPhysicianNotesForPatient = (
  id: string
): Promise<{ message: string; value: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/treatmentplan/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getFollowupNotesForPatient = (
  id: string
): Promise<{ message: string; value: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/follow-up/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IAddendum {
  text: string;
  creationDate: Date;
}

export const getAddendumsForPatient = (
  id: string
): Promise<{ message: string; items: IAddendum[] }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/addendums/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export interface IPrescription {
  id?: number;
  dispensableDrugId: number;
  medicationName: string;
  medicationId: string;
  dispense: string;
  refills: number;
  directionsForUse: string;
  pharmacyNotes: string;
  strength: string;
  ordered: boolean;
  dispenseId: number;
  doseSpotId?: string,
  status?: number,
  pharmacy?: IPharmacyDoseSpotInfo,
  daysSupply?: number,
  effectiveDate: Date | string,
  rxcui?: number
}
export interface IAddPrescriptionDoseSpot {
  prescription: IPrescription,
  doseSpotPrescription: IDoseSotPrescription | undefined
}
export const getPrescriptionsForPatient = (
  id: string
): Promise<{ message: string; items: IPrescription[] }> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/prescriptions/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const getPrescriptionStatusForPatient = (appointmentId: any, patientId: number) => {

  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/${appointmentId}/${patientId}/prescriptions`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const getPrescriptionPharmacyInformation = (appointmentId: any, pharmacyDoseSpotId: number) => {

  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/${appointmentId}/pharmacy-info/${pharmacyDoseSpotId}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const getPhysicianAppointmentDetails = (
  id: string
): Promise<IFullAppointment> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPhysicianAvailability = (): Promise<{ available: boolean }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/available",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const setPhysicianAvailability = (
  available: boolean
): Promise<{ available: boolean }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/available",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { available: available });
};

export interface IPhysicianSuccessActionResponse {
  appointmentId: string;
  status: string;
}

export interface IPhysicianFailureActionResponse {
  status: number;
  message: string;
  level: "error";
  timestamp: Date;
}

export const lockAppointment = (
  appointmentId: string
): Promise<
  IPhysicianSuccessActionResponse | IPhysicianFailureActionResponse
> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/lock",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const unlockAppointment = (
  appointmentId: string,
  reason: IUnlockReason
): Promise<
  IPhysicianSuccessActionResponse | IPhysicianFailureActionResponse
> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/unlock",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, reason);
};

export const setPhysicianNotes = (appointmentId: string, value: string) => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/note",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { text: value });
};

export const getPhysicianNotesForPhysician = (
  appointmentId: string
): Promise<{ text: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/note",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const setFollowupNotes = (appointmentId: string, value: string) => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/followup",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { text: value });
};

export const getFollowupNotesForPhysician = (
  appointmentId: string
): Promise<{ text: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/followup",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPrescriptionsForPhysician = (appointmentId: string): Promise<IPrescription[]> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/prescription",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};
export const getDispenseUnitsItems = (appointmentId: string): Promise<{ name: any, id: any }[]> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/dispenseUnits",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const addPrescription = (appointmentId: string, prescription: any): Promise<IPrescription> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/prescription",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, prescription);
};

export const removePrescription = (appointmentId: string, prescriptionId: number): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/prescription/" + prescriptionId,
    "DELETE",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const updatePrescription = (
  appointmentId: string,
  prescription: IPrescription
): Promise<IPrescription> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" +
    appointmentId +
    "/prescription/" +
    prescription.id,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, prescription);
};

export const getAttachment = (
  appointmentId: string,
  photoToken: string
): Promise<{ imageBase64: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/attachment/" + photoToken,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const searchMedication = (name: string, signal: AbortSignal,pageNumber:number = 1): Promise<IPaginatedResponse<IMedicationSearchItem>> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/dosespot/search/medication?name=${name}&pageNumber=${pageNumber}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined, signal);
};
export const searchPharmacy = (
  isPhysian: boolean,
  body: ISearchPharmacyParams
): Promise<any> => {
  let role = isPhysian ? "physician" : "patient";
  let url = `/api/${role}/dosespot/search/pharmacy`;
  if (body) {
    const queryParams = new URLSearchParams();
    Object.entries(body).forEach(([key, value]) => {
      if(value!='')
      queryParams.append(key, value);
    });
    url += `?${queryParams.toString()}`;
  }

  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IPhysicianAddendum {
  id?: string;
  text: string;
  doctorId?: number;
  appointmentId?: string;
  createdAt?: Date;
}

export const addAddendum = (
  appointmentId: string,
  addendum: string
): Promise<IPhysicianAddendum> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/addendum",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { text: addendum });
};

export const getPhysicianAddendums = (
  appointmentId: string
): Promise<IPhysicianAddendum[]> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/addendum",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getAppointmentInfoForPhysician = (
  appointmentId: string
): Promise<IAppointmentInfo> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/mini",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getAppointmentInfoForPatient = (
  appointmentId: string
): Promise<IAppointmentInfo> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/appointment/" + appointmentId + "/mini",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const completeAppointment = (
  appointmentId: string
): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/completion",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IUnlockReason {
  reasonId: string;
  reasonMessage: string;
  reasonLabel?: string;
}

export const getUnlockAppointmentReasons = (): Promise<IUnlockReason[]> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/reasonmessages",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IZoomAuth {
  userId: string;
  appointmentId: string;
}
export interface IZoomMeeting {
  created_at: string;
  duration: number;
  host_id: string;
  id: number;
  join_url: string;
  settings: {
    alternative_hosts: string[];
    approval_type: number;
    audio: string;
    auto_recording: string;
    close_registration: boolean;
    cn_meeting: boolean;
    enforce_login: boolean;
    enforce_login_domains: string[];
    global_dial_in_countries: string[];
    global_dial_in_numbers: [
      {
        city: string;
        country: string;
        country_name: string;
        number: string;
        type: string;
      }
    ];
    host_video: boolean;
    in_meeting: boolean;
    join_before_host: boolean;
    mute_upon_entry: boolean;
    participant_video: boolean;
    registrants_confirmation_email: boolean;
    use_pmi: boolean;
    waiting_room: boolean;
    watermark: boolean;
    registrants_email_notification: boolean;
  };
  start_time: string;
  start_url: string;
  status: string;
  timezone: string;
  topic: string;
  type: number;
  uuid: string;
}

export const getZoomMeetingLink = (auth: IZoomAuth): Promise<IZoomMeeting> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/meeting/",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, auth);
};
export const getMessagesFromServer = (
  appointmentId: string,
  pageSize: number,
  pageIndex: number,
  after?: Date
): Promise<IResponseMessageChat> => {
  let descriptor = new ServiceDescriptor(
    "/api/chat/appointment/" +
    appointmentId +
    "?pageSize=" +
    pageSize +
    "&pageIndex=" +
    pageIndex +
    (after ? "&after=" + after : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const postMessageChat: (
  appointmentId: string,
  data: IMessage
) => Promise<IMessage> = (appointmentId, data) => {
  let descriptor = new ServiceDescriptor(
    `/api/chat/appointment/${appointmentId}`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export interface IAppointmentUpdatedMessage {
  action: "appointmentUpdates";
  appointmentId: string;
}

export interface ISearch {
  patientName?: string;
  patientCode?: string;
  from?: Date;
  to?: Date;
  status?: AppointmentStatus;
  state?: string;
  appointmentNumber?: string;
}

export const startAppointment = (
  appointmentId: string
): Promise<
  IPhysicianSuccessActionResponse | IPhysicianFailureActionResponse
> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/start",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const cancelPhysicianAppointment = (
  appointmentId: string
): Promise<
  IPhysicianSuccessActionResponse | IPhysicianFailureActionResponse
> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/appointment/" + appointmentId + "/cancel",
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IAbsenceNotes {
  id?: number
  from?: Date;
  to?: Date;
  comment?: string;
  reasonForVisit?: string;
}

export const postAbsenceNote = (
  appointmentId: string,
  absenceNote: IAbsenceNotes
): Promise<IAbsenceNotes> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/${appointmentId}/absence-notes`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, absenceNote);
};

export const updateAbsenceNote = (
  appointmentId: string,
  absenceNote: IAbsenceNotes,
  absenNoteId: number
): Promise<IAbsenceNotes> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/${appointmentId}/absence-notes/${absenNoteId}`,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, absenceNote);
};

export interface IAbsenceNotesForReport {
  creationDate: Date | null;
  visitDate?: Date | null;
  from: Date | null;
  to: Date | null;
  comment?: string | null;
  reasonForVisit?: string | null;
  physician: {
    email?: string;
    name: string;
    title?: string;
    phone?: string;
    officePhone?: string;
    npi?: string;
  };
  patient: {
    name: string;
  };
}

export const getAbsenceNoteForPatient = (
  appointmentId: string
): Promise<IAbsenceNotesForReport> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/${appointmentId}/absence-notes`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IAbsenceNotesPhysician {
  from: Date;
  to: Date;
  comment?: string;
  reasonForVisit?: string
}

export const getAbsenceNoteForAppointment = (
  appointmentId: string
): Promise<IAbsenceNotesPhysician> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/${appointmentId}/absence-notes`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IAppointmentHistoryItem {
  timestamp: Date;
  action: string; //i.e "Appointment Booked", "Appointment Locked" ...
  actorType: "patient" | "physician" | "agent";
  actorName: string; //full name
  actorId: number;
  newStatus: AppointmentStatus;
}

export const getAllPhysicians = (pageIndex: number, pageSize: number, params?: any): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IPhysician[];
  length: number;
}> => {
  let url = `/api/admin/physician?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params) {
    url = url.concat(
      (params.name ? `&name=${params.name}` : "") +
      (params.status ? `&status=${params.status}` : "") +
      (params.joinDateFrom ? `&joinDateFrom=${params.joinDateFrom}` : "") +
      (params.joinDateTo ? `&joinDateTo=${params.joinDateTo}` : "") +
      (params.stateId ? `&stateId=${params.stateId}` : ""),
    )
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getPhysicianProfileImageForAdmin = (phId: number): Promise<{ imageBase64: string }> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/physician/" + phId + "/image/base64",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const enablePhysician = (enabled: boolean, phId: number): Promise<IPhysician> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/physician/" + phId + (enabled ? "/enable" : "/disable"),
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const enableAgentBiller = (action: any, agentId: number, isAgent: boolean): Promise<IAgent | IBiller> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/" + (isAgent ? "agent/" : "biller/") + agentId,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, action);
}
export const approvePhysicianState = (approve: boolean, phId: string, stateId: number): Promise<IPhysician> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/physician/" + phId + "/state/" + stateId + (approve ? "/approve" : "/reject"),
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export interface IConfigurationItem {
  key: string; // "verification_email",
  title: string;
  description: string;
  configurationType: "number" | "textarea" | "text" | "password" | "boolean";
  parameters: string[]; // values replaceable in the text example [ "##code##", "##userType##" ]
  value: string; // example "your ##userType## verification code is <strong>##code##</strong> "
}

export const getAdminConfigurationSettings = (): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IConfigurationItem[];
}> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/config?pageIndex=0&pageSize=500",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const updateAdminConfigurationSettings = (
  key: string,
  value: string
): Promise<IConfigurationItem> => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/config/" + key,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { value: value });
}

export const getAdminAllStates = (): Promise<{ items: IUSState[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/admin/state/lookup',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getSystemStates = (): Promise<{ items: number[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/admin/state',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const addSystemStates = (id: number): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    '/api/admin/state',
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { stateId: id });
};

export const removeSystemStates = (id: number): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    '/api/admin/state',
    'DELETE',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { stateId: id });
};

export const sendNewsletterEmails = () => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/send-newsletter-email",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const getAppointmentPaymentInfo = (): Promise<{ paymentAmount: number, transactionFee: number }> => {
  let descriptor = new ServiceDescriptor(
    '/api/patient/appointment-payment-amount',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const searchMedicationAllergies = (name: string, page: number): Promise<ISearchResultDto> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dosespot/search/medications-allergies?name=${name}`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const addMedicationAllergies = (data: IAddAllergy[], patientId?: number): Promise<any> => {
  let url = patientId ? `/api/patient/medications-allergies/${patientId}` : `/api/patient/medications-allergies`;
  let descriptor = new ServiceDescriptor(
    url,
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}
export const getMedicationAllergies = (patientId?: number): Promise<IFullAllergy[]> => {
  let url = patientId ? `/api/patient/medications-allergies/${patientId}` : `/api/patient/medications-allergies`;
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const deleteMedicationAllergies = (data: IFullAllergy[], patientId?: number): Promise<any> => {
  let url = patientId ? `/api/patient/medications-allergies/${patientId}` : `/api/patient/medications-allergies`;
  let descriptor = new ServiceDescriptor(
    url,
    'DELETE',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}
export const updateMedicationAllergies = (data: IFullAllergy[], patientId?: number): Promise<any> => {
  let url = patientId ? `/api/patient/medications-allergies/${patientId}` : `/api/patient/medications-allergies`;
  let descriptor = new ServiceDescriptor(
    url,
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}
export interface IDoctorFavoritePrescription {
  id?: number; //THIS IS THE DATABASE ID
  medicationName: string;
  medicationId: string; //THIS IS THE MEDICATION ID IN DOSESPOT
  dispense: string;
  refills: number;
  directionsForUse: string;
  pharmacyNotes?: string;
  strength: string;
  dispenseUnitId: number
}

export const getPhysicianFavoritePrescriptions = (signal?: AbortSignal, medicationName?: string): Promise<IDoctorFavoritePrescription[]> => {
  let descriptor = new ServiceDescriptor(
    medicationName ? `/api/physician/favorite-prescription/${medicationName}` : `/api/physician/favorite-prescription`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined, signal);
}

export const addPhysicianFavoritePrescriptions = (item: IDoctorFavoritePrescription): Promise<IDoctorFavoritePrescription> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/favorite-prescription',
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, item);
}

export const removePhysicianFavoritePrescriptions = (itemId: number): Promise<{ message: string }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/favorite-prescription/' + itemId,
    'DELETE',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getPhysicianTimeBeforeLock = (): Promise<{ lockAllowedBeforeScheduleTimeMinuets: number }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/lock-allowed-before-schedule-time-minuets',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getMedicationsAllergyStatus = (patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    patientId ? `/api/patient/status/medications-allergies/${patientId}` : `/api/patient/status/medications-allergies`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getPreviousConditionsStatus = (patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    patientId ? `/api/patient/status/previous-conditions/${patientId}` : `/api/patient/status/previous-conditions`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const editMedicationsAllergyStatus = (hasMedicationsAllergy: boolean, patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    patientId ? `/api/patient/status/medications-allergies/${patientId}` : `/api/patient/status/medications-allergies`,
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  const requestBody = { hasMedicationsAllergy }
  return genericRequest(descriptor, requestBody);
}

export interface IPatientPreviousConditions {
  conditions: Array<number>,
  hasPreviousCondition: boolean
}
export const updatePatientPreviousConditions = (data: IPatientPreviousConditions, patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    patientId ? `/api/patient/previous-conditions/${patientId}` : `/api/patient/previous-conditions`,
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}

export const editPreviousConditionsStatus = (data: any, patientId?: number) => {
  let descriptor = new ServiceDescriptor(
    patientId ? `/api/patient/status/previous-conditions/${patientId}` : `/api/patient/status/previous-conditions`,
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}

export const getAllergyInteractions = (patientId: number): Promise<AllergyInteractionResponse> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/patient/${patientId}/allergies/interactions`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const getDrugInteractions = (patientId: number): Promise<IDrugInteractionItem[]> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/patient/${patientId}/drugs/interactions`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const checkForNewPrescriptionInCompletedAppointment = (appointmentId: string) => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/${appointmentId}/check/new-prescriptions`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const submitPrescriptionAfterCompletion = (appointmentId: string) => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/appointment/${appointmentId}/complete/new-prescriptions`,
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const selectdoseSpotMedication = (dispensableDrugId: number, name: string): Promise<IDoseSpotPrescription> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/dosespot/select/medication?dispensableDrugId=${dispensableDrugId}&name=${name}`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export enum PatientMedicationStatusTypeEnum {
  Active = 1,
  Inactive = 2,
  Discontinued = 3,
  Deleted = 4,
  Completed = 5,
}
export interface IFullSelfReportedItem{
  name:string;
  strength:string;
  selfReportedMedicationItem:ISelfReportedItem;
  id?: number,
  doseSpotSelfReportedId?:number;
  
}
export interface ISelfReportedItem {
  DispensableDrugId: number;
  SelfReportedMedicationId: number;
  Refills?: number;
  DaysSupply?: number;
  DispenseUnitId?: number;
  Quantity: number;
  Directions?: string;
  WrittenDate?: Date;
  Status: PatientMedicationStatusTypeEnum;
  InactiveDate?: Date;
  Comment?: string;
  Encounter?: string;
}

export const searchSelfReportedMedication = (name: string): Promise<IPaginatedResponse<IMedicationSearchItem>> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dosespot/self-reported-medications/search?name=${name}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const selectSelfReportedMedication = (dispensableDrugId: number, name: string): Promise<IDoseSpotPrescription> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dosespot/self-reported-medications/select?dispensableDrugId=${dispensableDrugId}&name=${name}`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const addSelfReportedMedicationService = ( item: IFullSelfReportedItem, patientId?: number): Promise<IDoseSpotPrescription> => {
  let descriptor = new ServiceDescriptor(
    '/api/patient/self-reported-medications' + (patientId ? `/${patientId}` : ''),
    'POST',
    defaultProvide,
    defaultUpdater
  );

  const requestBody = {
    item: {
      ...item.selfReportedMedicationItem,
      SelfReportedMedicationId: undefined
    },
    name: item.name,
    strength: item.strength
  }
  return genericRequest(descriptor, requestBody);
};


export const removeSelfReportedMedicationService = (id: number, patientId?: number): Promise<void> => {
  let descriptor = new ServiceDescriptor(
    '/api/patient/self-reported-medications' + (patientId ? `/${patientId}` : '') + `/${id}`,
    'DELETE',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const listSelfReportedMedications = (patientId?: number): Promise<IFullSelfReportedItem[]> => {
  let descriptor = new ServiceDescriptor(
    '/api/patient/self-reported-medications' + (patientId ? `/${patientId}` : ''),
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const checkForAppointmentRightAge = () => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/check-age`,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const canRegister = (email: string): Promise<{ canRegister: boolean }> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/can-register/` + email,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const listRxChangeAppointments = (): Promise<{ items: IAppointmentInfo[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/rx-change/appointments',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}


export const listRxChangeReconcile = (): Promise<{ items: IRxReconcile[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/rx-change/unmapped-requests',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const reconcileMedicine = (
  rxChangeId: String,
  doseSpotId: string
) => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/rx-change/unmapped-requests/" + rxChangeId + "/reconcile",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { doseSpotPrescriptionId: doseSpotId });
};

export interface IPrescriptionReconcile {
  id: string,
  medication: string,
  dose_spot_id: string,
  rx_change_request_id: string

}

export const getALlAppointmentsOfRxReconcile = (rxReconcileID: string): Promise<{ prescriptions: IPrescriptionReconcile[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/rx-change/unmapped-requests/' + rxReconcileID + "/prescriptions",
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getRxChangeRequests = (appointmentId: string): Promise<{ items: IRxChangeRequest[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/appointment/' + appointmentId + '/rx-change?requiresAction=true',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}



export const getRxChangeDenialReasons = (): Promise<{ items: IRxChangeDenialReason[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/lookup/rx-change-denial-reasons',
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const approveRxChange = (appointmentId: string, rxChangeId: number, prescriptionId: number, comment?: string, priorAuthorization?: string)
  : Promise<{ items: IRxChangeRequest[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/appointment/' + appointmentId + '/rx-change/' + rxChangeId + '/approve',
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { selectedMedicationId: prescriptionId, comment: comment, priorAuthorization: priorAuthorization });
}

export const denyRxChange = (appointmentId: string, rxChangeId: number, reasonId: number, comment: string)
  : Promise<{ items: IRxChangeRequest[] }> => {
  let descriptor = new ServiceDescriptor(
    '/api/physician/appointment/' + appointmentId + '/rx-change/' + rxChangeId + '/deny',
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { reasonId: reasonId, comment: comment });
}

export const deleteDependent = (dependentPatientId: number): Promise<any> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/dependent/${dependentPatientId}`,
    'DELETE',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const logInAsPaient = (patient: IPatientForAgents, isUser: boolean): Promise<any> => {
  const url = isUser ? `/api/agent/login-as-patient/${patient.id}` : `/api/agent/login-via-dependent/${patient.id}`;
  let descriptor = new ServiceDescriptor(
    url,
    'POST',
    defaultProvide,
    defaultUpdater
  )
  return genericRequest(descriptor, patient);
}

export const getAgentStatus = (): Promise<any> => {
  const url = `/api/agent/status`
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getAllAgents = (pageIndex: number, pageSize: number, params?: any): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IPhysician[];
  length: number;
}> => {
  let url = `/api/admin/agent?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params) {
    url = url.concat(
      (params.name ? `&name=${params.name}` : "") +
      (params.status ? `&status=${params.status}` : "") +
      (params.joinDateFrom ? `&joinDateFrom=${params.joinDateFrom}` : "") +
      (params.joinDateTo ? `&joinDateTo=${params.joinDateTo}` : "") +
      (params.stateId ? `&stateId=${params.stateId}` : ""),
    )
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};




export const getAllBillers = (pageIndex: number, pageSize: number, params?: any): Promise<{
  pageIndex: number;
  pageSize: number;
  items: IBiller[];
  length: number;
}> => {
  let url = `/api/admin/billers?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params) {
    url = url.concat(
      (params.name ? `&name=${params.name}` : "") +
      (params.status ? `&status=${params.status}` : "") +
      (params.joinDateFrom ? `&joinDateFrom=${params.joinDateFrom}` : "") +
      (params.joinDateTo ? `&joinDateTo=${params.joinDateTo}` : "") +
      (params.stateId ? `&stateId=${params.stateId}` : ""),
    )
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};


export const getBillerStatus = (): Promise<any> => {
  const url = `/api/biller/status`
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}


export const getPatientsForAgent = (
  pageIndex: number,
  pageSize: number,
  params?: IListPatientsRequestQuery
): Promise<IListPatientsResponse> => {
  let url: string = `/api/agent/patients?pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (params) {
    url = url.concat(
      ((params.name && params.name !== "") ? `&name=${params.name}` : "") +
      ((params.phoneNumber && params.phoneNumber !== "") ? `&phoneNumber=${params.phoneNumber}` : "") +
      (params.appointmentStatus ? `&appointmentStatus=${params.appointmentStatus}` : "") +
      (params.joinDateFrom ? `&joinDateFrom=${params.joinDateFrom}` : "") +
      (params.joinDateTo ? `&joinDateTo=${params.joinDateTo}` : "") +
      (params.stateId ? `&stateId=${params.stateId}` : "") +
      ((params.emailAddress && params.emailAddress !== "") ? `&emailAddress=${params.emailAddress}` : "") +
      (params.dob ? `&dob=${params.dob}` : "") +
      (params.patientId ? `&patientId=${params.patientId}` : '')
    );
  }
  let descriptor = new ServiceDescriptor(
    url,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const downloadAbsenceNote = (appointmentId: string) => {
  let baseUrl = appConfig.baseUrl;
  let url = `${baseUrl}/api/report/${appointmentId}/generate-absence-note-pdf`;
  return fetch(url);
}
export const sendAppointmentDetailsByEmailByAgent = (appointmentId: string, patientId: number) => {
  const url = `/api/agent/send-appointment-details/${appointmentId}/${patientId}`
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const sendAbsenceNoteByEmailByAgent = (appointmentId: string, patientId: number) => {
  const url = `/api/agent/send-absence-notes/${appointmentId}/${patientId}`
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const patientAuthenticationByAgent = (data: any) => {
  const url = `/api/agent/verify/patient`
  let descriptor = new ServiceDescriptor(
    url,
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}
export const logAgentUpdatingPatientMedicalHistory = (dependentId?: number) => {
  const url = (dependentId) ?
    `/api/patient/agent/update-medical-history/${dependentId}` : `/api/patient/agent/update-medical-history`;
  let descriptor = new ServiceDescriptor(
    url,
    'POST',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const emailSubscribe = (): Promise<any> => {
  const url = `/api/patient/email/subscribe`;
  let descriptor = new ServiceDescriptor(
    url,
    'PUT',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const emailUnSubscribe = (id: string): Promise<any> => {
  const url = `/api/patient/email/unsubscribe/${id}`;
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export const unauthenticatedEmailUnSubscribe = (id: string): Promise<any> => {
  const url = `/api/patient/unsubscribe/${id}`;
  let descriptor = new ServiceDescriptor(
    url,
    'GET',
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}
export enum PrescriptionStatusType {
  Entered = "Entered",
  Printed = "Printed",
  Sending = "Sending",
  eRxSent = "eRxSent",
  FaxSent = "FaxSent",
  Error = "Error",
  Deleted = "Deleted",
  Requested = "Requested",
  Edited = "Edited",
  EpcsError = "EpcsError",
  EpcsSigned = "EpcsSigned",
  ReadyToSign = "ReadyToSign",
  PharmacyVerified = "PharmacyVerified",
}
export interface IPharmacyDoseSpotInfo {
  id: any,
  name: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  phone: string,
  fax: string
}
export interface Email {
  email: string | null
}

export const resendActivationEmail = (data: Email) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/resend-activation-email",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};



export const getPayerList = (pageIndex: number, pageSize: number): Promise<{
  pageIndex: number;
  pageSize: number;
  payers: IPayerPreview[];
}> => {
  let descriptor = new ServiceDescriptor(
    `/api/admin/payers?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}




export const addNewPayer = (data: IPayerPreview) => {
  let descriptor = new ServiceDescriptor(
    "/api/admin/payer",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};



export const editPayer = (data: IPayerPreview) => {
  let descriptor = new ServiceDescriptor(
    `/api/admin/payer/${data.id}`,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}




export const deletePayer = (data: IPayerPreview) => {
  let descriptor = new ServiceDescriptor(
    `/api/admin/payer/${data.id}`,
    "DELETE",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}

export const getPayersListForPatient: () => Promise<
  IPayerPreview[]
> = () => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/insurance/list-payers?pageIndex=0&pageSize=50",
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};


export const getConfigurationForPayer = (payerCode: string | undefined | null = "13162"): Promise<any> => {
  let descriptor = new ServiceDescriptor(
    `/api/patient/insurance/configurations?payerCode=${payerCode}`, // todo make sure we call the right code
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};



export const addInsuranceForPatient = (
   pollingEnds: (responseBody: InsuranceCoverageCheckResponse) => boolean,
   data: {},
   dependentId?: number
): Promise<InsuranceCoverageCheckResponse> => {
   let descriptor = new ServiceDescriptor(
      '/api/patient/insurance' + (dependentId ? '/' + dependentId : ''),
      'POST',
      defaultProvide,
      defaultUpdater
   );

   return genericPollingRequest(descriptor, data, 2000, 10000, pollingEnds);
};



export const getUserHasInsurance = (patientId?: number): Promise<IInsuranceResponse> => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/insurance" + (patientId ? "/" + patientId : ""),
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
}


export const getCoPayment = (
   insuranceId: string,
   pollingEnds: (responseBody: ICopayment) => boolean
): Promise<ICopayment> => {
   let descriptor = new ServiceDescriptor(
      `/api/patient/copayment/${insuranceId}`,
      'GET',
      defaultProvide,
      defaultUpdater
   );
   return genericPollingRequest(
      descriptor,
      undefined,
      2000,
      10000,
      pollingEnds
   );
};




export const deleteInsurancePatient = (dependentId?: number) => {
  let descriptor = new ServiceDescriptor(
    "/api/patient/insurance" + (dependentId ? "/" + dependentId : ""),
    "DELETE",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};


export const getBillingManageCapture = (
  id: string
): Promise<IAppointementBilling> => {
  let descriptor = new ServiceDescriptor(
    "/api/biller/appointment-billing/" + id,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};

export const getBillingLogs = (
  id: string,
  pageIndex: number,
  pageSize: number,

): Promise<{
  logs: IBillingLogs[],
  count: number
  pageIndex: number
  pageSize: number
}> => {
  let descriptor = new ServiceDescriptor(
    "/api/biller/appointment-billing-logs/" + id + "?pageIndex=" + pageIndex + "&pageSize=" + pageSize,
    "GET",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, undefined);
};


export const chargeAmountAppointment = (data: DataForAmount, appointmentId: string | undefined) => {
  let descriptor = new ServiceDescriptor(
    "/api/biller/charge-appoitment/" + appointmentId,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const refundAmountAppointment = (data: DataForAmount, appointmentId: string | undefined) => {
  let descriptor = new ServiceDescriptor(
    "/api/biller/refund-appointment/" + appointmentId,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
}

export const appointmentBillingUpdate = (data: IAppointementBillingUpdate, appointmentId: string | undefined) => {
  let descriptor = new ServiceDescriptor(
    "/api/biller/appointment-billing/" + appointmentId,
    "PUT",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, data);
};

export const setAppointmentCpt = (
  code: string,
  name: string,
  appointmentId?: string
): Promise<any> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/" + appointmentId + "/cpt",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { cptCoded: code, cptName: name });
};


export const searchDiagnosis = (name: string, signal: AbortSignal): Promise<IDiagnosis[]> => {
  let descriptor = new ServiceDescriptor(
    `/api/physician/icdapi/search`,
    "POST",
    defaultProvide,
    defaultUpdater
  );
  return genericRequest(descriptor, { term: name }, signal);
};

export const setAppointementDiagnosis = (
  code?: string,
  name?: string,
  secondaryDiagnosis?: string,
  appointmentId?: string
): Promise<any> => {
  let descriptor = new ServiceDescriptor(
    "/api/physician/" + appointmentId + "/diagnosis",
    "POST",
    defaultProvide,
    defaultUpdater
  );
  const submittedObj: any = { diagnosisCoded: code, diagnosisName: name }
  if (secondaryDiagnosis) {
    submittedObj["secondDiagnosis"] = secondaryDiagnosis;
  }

  return genericRequest(descriptor, submittedObj);
};